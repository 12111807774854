<template>
  <div id="app">
     <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>

html,body {
  margin:0;
  padding:0;
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  margin:0 auto;
  /* background: url("./assets/image.png")no-repeat; */
  /* background-size: 100% 100%; */
  /* overflow: hidden; */
  user-select: none;
  
}

a{
  text-decoration: none;
  color: aqua;;
}
  /* a:hover, a:visited, a:link, a:active {
  color:#333;
} */
button{
  outline: 0;
}
ul,li{
  padding:0;
  margin:0;
  list-style: none;
}
*::after , *::before{
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-box-sizing: baseLine;
}
@media(max-height:700px){
  #app{
    height: 95%;
  }
}

.el-picker-panel{
    background: rgb(25,33,39)!important;
    /* color: #606266; */
    color: #C0C4CC!important;
}
.el-picker-panel__footer {
    background: rgb(25,33,39)!important;
}

.el-date-range-picker__editor .el-input__inner{
  background: rgb(25,33,39)!important;
  color: #C0C4CC!important;

}

.el-date-table td.in-range div{
  background: rgb(25,33,39)!important;
}

.el-button.is-disabled.is-plain{
  background: rgb(25,33,39)!important;
  border: none;
}
.el-button.el-picker-panel__link-btn {
  background: rgb(25,33,39)!important;
  border:none;
}

.el-date-editor.el-range-editor.el-input__inner.el-date-editor{
  margin-left:-65%!important;
}
.el-date-table td.next-month, .el-date-table td.prev-month{
  /* color: #C0C4CC; */
  color: #606266!important;
}

.el-button--text{
  color:#606266!important;
}

.el-time-panel{
  margin-left:-30px;
}

</style>
