const getters = {
  token: state => state.user.token,
  // nickname: state => state.user.nickname,
  // phone: state => state.user.phone,
  // image: state => state.user.image,
  // integral: state => state.user.integral,
  // vip: state => state.user.vip,
  // real_name: state => state.user.real_name,
  // money: state => state.user.money,
  // user_id:state => state.user.user_id,
  userInfo:state => state.user.userInfo,
  loadingState: state => state.user.loadingState,
}
export default getters
