<template>
  <div v-if="isshow" class="show-message">
    <div class="title">{{ title }}</div>
    <div class="content">{{ content }}</div>
    <div class="btn-container">
      <!-- <div class="btn" @click="cancel()">取消</div> -->
      <div class="btn" @click="confirm()">确定</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "提示",
    },
    content: {
      type: String,
      default: "确定退出平台吗？",
    },
  },
  data() {
    return {
      isshow: true,
    };
  },
  //   render(h,ctx) {
  //     return (
  //       <div class="show-message">
  //         <div class="title">{title}</div>
  //         <div class="content">{content}</div>
  //         <div class="btn-container">
  //           <div class="btn" onClick={() => cancel()}>
  //             取消
  //           </div>
  //           <div class="btn" onClick={() => confirm()}>
  //             确定
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   },
  methods: {},
};
</script>

<style scoped>
.show-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
  height: 190px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: inset 0px 0px 0px 2px #3d4f56;
  background: #3d4f56;
  background: url("../../assets/message_bg.png") no-repeat;
  background-size: 100% 100%;
  animation: drive 900ms;
  color: #fff;
  z-index: 2015;
}

.title {
  text-align: left;
  font-size: 16px;
  font-weight: 350px;
  color: #b0bab8;
}

.content {
  text-align: left;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 350;
  color: #b0bab8;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 56px;
}

.btn {
  width: 78px;
  height: 28px;
  background: #495861;
  border: 1px solid #263340;
  font-size: 14px;
  color: #26aae3;
  vertical-align: center;
  text-align: center;
  line-height: 28px;
  margin: 0 10px;
  cursor: pointer;
  /* margin: 0 10px;
    padding: 0 10px; */
}

@keyframes drive {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
</style>
