<template>
  <div class="contain" v-if="isshow">
    <div class="contain_log">
      <img :src="success1" v-if="type == 'success1'" />
      <img :src="success2" v-if="type == 'success2'" />
      <img :src="err" v-if="type == 'err'" />
      <img :src="report" v-if="type == 'report'" />
    </div>
    <div class="contain_title">{{ title }}</div>
    <div class="contain_info">{{ info }}</div>
    <div style="margin-left: 53px; margin-top: 24px" @click="confirm()">
      <button class="btn">确定</button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    info: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      success1: require("../../assets/message/success_blue.png"),
      success2: require("../../assets/message/success_green.png"),
      err: require("../../assets/message/err_red.png"),
      report: require("../../assets/message/report_yellow.png"),
      isshow: true,
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.contain {
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 372px;
  height: 301px;
  border-radius: 16px;
  background: #2a3641;
  z-index:999;
  .contain_log {
    margin-top: 38px;
    text-align: center;
    height: 76px;
  }
  .contain_title {
    text-align: center;
    height: 35px;
    font-size: 24px;
    font-weight: 500;
    color: #e5e5e5;
  }
  .contain_info {
    height: 23px;
    font-size: 16px;
    color: #878787;
    text-align: center;
  }
}
.btn {
  width: 248px;
  height: 42px;
  background: radial-gradient(ellipse at center, #259ecd, #197fa9);
  border: none;
}
</style>
