import Vue from "vue";
import confirmBox from "../../components/confirmBox";
//生成一个vc
const ConfirmConstructor = Vue.extend(confirmBox);
function showConfirm(options) {
  return new Promise((resolve, reject) => {
    const confirmDom = new ConfirmConstructor();
    confirmDom.title = options.title || confirmDom.title;
    confirmDom.content = options.content || confirmDom.content;
    confirmDom.$mount(document.createElement("div"));
    document.body.appendChild(confirmDom.$el);
    confirmDom.confirm = function () {
      resolve();
      confirmDom.isshow = !confirmDom.isshow;
    };
    confirmDom.cancel = function () {
      reject();
      confirmDom.isshow = !confirmDom.isshow;
    };
  });
}
function registeryConfirm() {
  //将showConfirm函数注册到Vue原型链上
  Vue.prototype.$confirm = showConfirm;
}

export default registeryConfirm;
