import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import {
	DatePicker,
	Steps,
	Step,
	Card,
	RadioGroup,
	Radio,
	Input,
	Button,
	Form,
	FormItem,
	Empty,
	CheckboxGroup,
	Checkbox,
	Dialog,
	Upload,
	Select,
	Option,
	Row,
	Col,
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router/index";
import MessageBox from "./utils/messageBox";
import ConfirmBox from "./utils/confirmBox";
import store from "@/store";
import "./perssion";
import drag from "@/directives/drag/drag";
import _debounce from "./mixins/index.js";

// 全局注册自定义指令
import loading from "./directives/loading";

Vue.use(loading);
Vue.use(DatePicker);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Card);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Input);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Empty);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(MessageBox);
Vue.use(ConfirmBox);
Vue.use(Dialog);
Vue.use(drag);
Vue.mixin(_debounce);
Vue.use(Upload);
Vue.use(Select);
Vue.use(Option);
Vue.use(Row);
Vue.use(Col);
Vue.config.productionTip = false;
new Vue({
	store,
	router,
	render: (h) => h(App),
	beforeCreate() {
		Vue.prototype.$bus = this;
	},
}).$mount("#app");
