<template>
  <div v-show="visible" class="loading-wrap">
    <div class="loading-box">
      <div class="loading-add"></div>
      <div class="loading-txt">加载中...</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
};
</script>

<style lang="less" scoped>
.loading-wrap {
  white-space: nowrap;
}

.loading-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  font-size: 16px;
  white-space: nowrap;
  user-select: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.loading-add {
  width: 30px;
  height: 30px;
  border: 2px solid turquoise;
  border-top-color: transparent;
  border-radius: 100%;
  animation: add infinite 0.75s linear;
}
@keyframes add {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.loading-txt {
  margin-top: 5px;
  color: turquoise;
}
</style>
