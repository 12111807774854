import router from "./router";
import store from "./store";
import Vue from "vue";
import { getToken } from "./utils/auth";
router.beforeEach((to, from, next) => {
    if (to.path != "/gifArea" && to.path != "/gameCenter") {
      next();
    } else {
      const hasToken = getToken();
      if (hasToken) {
        next();
      } else {
        Vue.prototype
        .$confirm({
          title: "提示",
          content: "请登录后，在进行操作！",
        })
        .then(() => {
          //不做任何处理
          next({ path: from.path });
        })
        .catch(() => {});
      }
    }
  
 
});

// if(to.path == "/gameCenter"){
//   Vue.prototype
//   .$confirm({
//     title: "提示",
//     content: "正在开发，尽情期待！",
//   })
//   .then(() => {
//     //不做任何处理
//     next({ path: from.path });
//   })
//   .catch(() => {});
// }else{}