import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/api/login-userLogin',
    method: 'post',
    data
  })
}


export function logout() {
  return request({
    url: '/api/login-outLogin',
    method: 'get'
  })
}

export function getInfo() {
  return request({
    url:'/api/user-userInfo',
    method:'get'
  })
}

export function wechat(){
  return request({
    url:'/wechat/getticket',
    method:'post'
  })
}

export function checklogin(data){
  return request({
    url:'/wechat/checklogin',
    method:'post',
    data
  })
}

export function wechatLogout(data){
  return request({
    url:'/pc/usercenter-loginout',
    method:'post',
    data
  })
}