export default {
    install: Vue => {
      Vue.directive('drag', {
        // 只调用一次，指令第一次绑定到元素时调用，用这个钩子函数可以定义一个在绑定时执行一次的初始化动作
        bind: function () { },
        inserted: function (el, binding) {
          let dragable = false
          el.onmousedown = function (e) {
            if(e.target.nodeName == 'INPUT' || e.target.nodeName == 'TEXTAREA'){return ;}
            dragable = true
            var dx = e.clientX - el.offsetLeft
            var dy = e.clientY - el.offsetTop
            el.onmousemove = function (e) {
              if (dragable) {
                // e.stopPropagation()
                //获取父类当前高度
                let pageWidth = window.innerWidth;
                let pageHeight = window.innerHeight;
                //获取元素官高
                let elWidth = el.offsetWidth;
                let elHeight = el.offsetHeight;
                //最大移动距离
                let maxX = pageWidth -elWidth ;
                let maxY = pageHeight - elHeight ;
                //鼠标移动距离
                var left = e.clientX - dx 
                
                var top = e.clientY - dy 
                //鼠标移动距离
                var left = Math.min(maxX - 10,Math.max(0,left))
                var top = Math.min(maxY - 40,Math.max(40,top))
                el.style.left = left + 'px'
                el.style.top = top + 'px'
              }
            }
            document.onmouseup = function (e) {
              dragable = false
              e.onmousemove = null
              document.mouseup = null
            }
            return false
          }
        },
      })
    }
  }
  