import Vue from "vue";
import Loading from "./loading.vue";

const Mask = Vue.extend(Loading);

const toggleLoading = (el, binding) => {
  if(binding.value) {
    Vue.nextTick(() => {
      // 控制loading组件显示
      el.instance.visible = true;
      // 插入到目标元素
      insertDom(el, el, binding)
    }) 
  }else{
    el.instance.visible = false;
  }
}

const insertDom = (parent, el) => {
  parent.appendChild(el.mask)
}

export default {
  // bind：只调用一次，指令第一次绑定到元素时调用。在这里可以进行一次性的初始化设置。
  bind: function (el,binding,vnode) {
    // new Mask()的时候，把该组件实例挂载到一个div上
    const mask = new Mask({
      el: document.createElement('div'),
      data() {return {}}
    })
    el.instance = mask;
    el.mask = mask.$el;
    binding.value && toggleLoading(el,binding)
  },
  update: function (el, binding) {
    if(binding.oldValue !== binding.value) {
      toggleLoading(el, binding)
    }
  },
  unbind: function (el, binding) {
    el.instance && el.instance.$destroy()
  }
}
