
import Vue from 'vue'
import messageBox from '../../components/messageBox'

    const MessageConstructor = Vue.extend(messageBox);
    function showMessage(options){
        return new Promise((resolve,reject)=>{
            const messageDom = new MessageConstructor()
            messageDom.title = options.title || messageDom.title
            messageDom.info = options.info || messageDom.info
            messageDom.type = options.type 
            messageDom.$mount(document.createElement('div'))
            document.body.appendChild(messageDom.$el)
            messageDom.confirm = function() {
                resolve();
                messageDom.isshow = !messageDom.isshow
            }
        })
       
    }
    function registeryMessage(){
        Vue.prototype.$message = showMessage
    }
    
    export default registeryMessage