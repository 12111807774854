import { login, logout, getInfo, wechat, wechatLogout } from "../../api/user";
import { getToken, setToken, removeToken } from "../../utils/auth";
import Vue from "vue";

const state = {
	token: getToken(),
	// nickname: '',
	// image: '',
	// integral: '',
	// vip:'',
	// real_name:'',
	// money:'',
	// user_id:'',
	userInfo: {},
	loadingState: false,
};

const mutations = {
	SET_USERINFO: (state, userInfo) => {
		Vue.set(state, "userInfo", userInfo);
	},
	SET_TOKEN: (state, token) => {
		state.token = token;
		setToken(token);
	},
	SET_LOADING: (state, loadingState) => {
		Vue.set(state, "loadingState", loadingState);
	},
};
// 1.请求登录,获取token并存储
const actions = {
	// user login
	login({ commit }, userInfo) {
		const { username, password } = userInfo;
		return new Promise((resolve, reject) => {
			login({ username: username.trim(), password: password })
				.then((response) => {
					const { data, code, msg } = response;
					setToken(data.token);
					commit("SET_TOKEN", data.token);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	//2.请求用户信息
	getInfo({ commit, state }) {
		return new Promise((resolve, reject) => {
			getInfo()
				.then((res) => {
					const { data } = res;
					if (!data) {
						reject("验证失败，请重新登录");
					}
					const userInfo = data;
					commit("SET_USERINFO", userInfo);
					resolve(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	// user logout(退出登录,删除token)
	logout({ commit, state, dispatch }) {
		return new Promise((resolve, reject) => {
			// logout(state.token).then(() => {
			//   commit('SET_TOKEN', '')
			//   removeToken()
			//   resolve()
			// }).catch(error => {
			//   reject(error)
			// })
			wechatLogout({ user_id: state.userInfo.id, origin: "pc" })
				.then((res) => {
					commit("SET_TOKEN", "");
					removeToken();
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	changeLoading({ commit }, loadingState) {
		return new Promise((resolve, reject) => {
			commit("SET_LOADING", loadingState);
			resolve();
		});
	},
};

export default {
	state,
	mutations,
	actions,
};
