import VueRouter from "vue-router";
import Vue from "vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home"),
    redirect: "/command",
    children: [
    
      {
        path: "/command",
        name: "command",
        component: () => import("../views/command"),
      },
      {
        path: "/recommend",
        name: "recommend",
        component: () => import("../views/recommend"),
      },
      {
        path: "/live",
        name: "livearea",
        component: () => import("../views/livearea"),
      },
      {
        path: "/live/table",
        name: "liveTable",
        component: () => import("../views/livearea/indexTable"),
      },
      {
        path:"/gifArea",
        name:"gifAre",
        component: ()=>import("../views/gifArea")
      },
      {
        path:"/gameCenter",
        name:"gameCeenter",
        component:()=>import("../views/gameCenter")
      }
    ],
  },
 

];
const router = new VueRouter({
  routes,
  mode: "history",
});
export default router;
